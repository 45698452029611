import React, {useState, useEffect} from 'react';
import './App.css';



function App() {

  const [data, setData] = useState({});

  const getData = async () => {
    const URL = 'https://my-typescript-worker.gxp51894686826.workers.dev/';
    const data = await fetch(URL);

    const d = await data.json();
    setData(d);
  }

  useEffect(() => {
    console.log('Init');
    getData()

    return () => {
      console.log('Dispose')
    }
  }, [])

  return (
    <div className="App">
      {JSON.stringify(data)}
    </div>
  );
}

export default App;
